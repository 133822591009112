import React from 'react';
import Legal from '../../components/legal/legal';
import PolicyItem from "../../components/policy-item/policy-item";
import './terms.scss';
import Layout from "../../components/layout/layout";
import termsData from "../../content/terms.yaml";
import {ToastContainer} from "react-toastify";

const LegalTerms = () => {
    return (
        <Layout>
            <ToastContainer/>
            <Legal title={termsData.title}>
                <div className={"head-content"}>
                    {termsData.head}
                </div>
                {
                    termsData.items.map((item, index) =>
                        (
                            <PolicyItem
                                key={index}
                                title={item.data.title}
                                content={item.data.content}
                            />
                        )
                    )
                }
                </Legal>
        </Layout>
    )
}

export default LegalTerms
